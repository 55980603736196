import React from "react"
import * as Chakra from "@chakra-ui/react"
import { graphql } from "gatsby"
import SEO from "gatsby-plugin-wpgraphql-seo"
import LeftArrow from "../images/left-arrow.svg"
import RightArrow from "../images/right-arrow.svg"
import Play from "../images/Play.svg"
import Photography from "../images/Photography.svg"
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'

import Plyr, {getVimeoHashedURL} from "../utils/plyr"
import {Global, css} from "@emotion/react";

const VideoSection = ({ url, index }) => {
  const [video, setVideo] = React.useState(null)

  React.useEffect(() => {
    if (video !== null) return
    fetch(`https://noembed.com/embed?url=${url}?n`)
      .then(res => res.json())
      .then(json => setVideo(json))
      console.log(video)
  })

  return (
    <Chakra.Box
      height="100vh"
      className="keen-slider__slide"
      width="100%"
      key={index}
      css={{
        "@media (max-width: 767px)": {
          height: "unset"
        },
      }}
    >
      <Chakra.Flex
        maxWidth="1440px"
        m="auto"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        css={{
          iframe: {
            maxWidth: "100%",
            width: "100%",
            height:'auto',
            minHeight: '60vh',
          },
            "@media (max-width: 1200px)": {
                iframe: {
                    height: "unset",
                    minHeight: "40vh",
                }
            }
        }}
        dangerouslySetInnerHTML={{__html: video?.html }}
      />
    </Chakra.Box>
  )
}

const WpPost = ({ data, pageContext }) => {

  let { primaryContentType: type, gallery } = data?.wpPost?.postGallery
  let category = data?.wpPost?.categories?.nodes[0]?.name
  let title = data?.wpPost?.title
    const [loaded, setLoaded] = React.useState(false)


    const [sliderRef, slider] = useKeenSlider({
        slides: {
            perView: 1
        },
        loop: true,
        created() {
            setLoaded(true)
        },
    })

  return (
    <Chakra.Flex flexGrow={1} width="100%" minHeight="100vh" align='center' justify='center' backgroundColor="#f2f2f2" css={{
        marginTop: "-140px",
        "@media (max-width: 992px)": {
            marginTop: "-140px"
        },
        "@media (max-width: 767px)": {
            marginTop: "-100px"
        }
    }}>
      <SEO post={data?.wpPost} />
      <Chakra.Box
        width="100%"
        display="flex"
        position="relative"
        alignItems="flex-start"
        flexDirection="column"
        justifyContent="flex-start"
        backgroundColor="#f2f2f2"
        css={{
          "@media (max-width: 767px)": {
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Chakra.Box
          width="100%"
          height="100%"
          display='flex'
          align='center'
          justify='center'
          position="relative !important"
          css={{
            "@media (max-width: 767px)": {
              position: "relative",
              height: "unset"
            },
          }}
          transition="opacity 0.5s"
          style={{opacity: loaded ? 1 : 0}}
        >
            {/*<Chakra.Box
                width="100%"
                height="100%"
                display="flex"
                alignItems="center"
                position="fixed"
                top="0"
                flexDirection="row"
                justifyContent="space-between"
                left="0"
                css={{
                    "@media (max-width: 991px)": {
                        flexDirection: "column",
                        justifyContent: "center",
                    },
                    "@media (max-width: 767px)": {
                        alignItems: "flex-end",
                    },
                }}
            >
                <Chakra.Flex
                    maxWidth="1440px"
                    px="32px"
                    margin="auto"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {gallery && gallery.length > 1 && <Chakra.Button
                        ref={leftButtonRef}
                        zIndex={10}
                        color="black"
                        display="inline-block"
                        padding="0.5rem 1rem"
                        borderColor="transparent"
                        borderWidth="1px"
                        alignSelf="center"
                        borderRadius="4px"
                        onClick={() => sliderRef?.current?.slickPrev()}
                        backgroundColor="transparent"
                        css={{
                            "@media (max-width: 991px)": {
                                display: "none",
                            },
                        }}
                    >
                        <LeftArrow />
                    </Chakra.Button>}

                    {gallery && gallery.length > 1 && <Chakra.Button
                        zIndex={10}
                        ref={rightButtonRef}
                        display="inline-block"
                        padding="0.5rem 1rem"
                        borderColor="transparent"
                        borderWidth="1px"
                        alignSelf="center"
                        borderRadius="4px"
                        onClick={() => sliderRef?.current?.slickNext()}
                        backgroundColor="transparent"
                        css={{
                            "@media (max-width: 991px)": {
                                display: "none",
                            },
                        }}
                    >
                        <RightArrow />
                    </Chakra.Button>}
                </Chakra.Flex>
            </Chakra.Box>*/}
            <Chakra.Flex
                maxWidth="1440px"
                px="32px"
                margin="auto"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
            >
                <Chakra.Box position="absolute" width="100%" height="100vh" left="0"
                    css={{
                        "@media (max-width: 1200px)": {
                            position: "relative",
                            height: "auto"
                        }
                    }}
                >
                  <Chakra.Box
                      ref={sliderRef}
                      width="100%"
                      height="100%"
                      className="keen-slider"
                  >
                    {gallery &&
                    gallery.map(({ fieldGroupName, image, video, gif }, index) => {
                      if (fieldGroupName === "Post_Postgallery_Gallery_Image") {
                        return (
                            <Chakra.Box
                                className="keen-slider__slide"
                                height="100vh"
                                width="100%"
                                key={index}
                                css={{
                                  "@media (max-width: 767px)": {
                                    paddingLeft: "26px",
                                    paddingRight: "26px",
                                    height: "unset"
                                  },
                                }}
                            >
                              <Chakra.Flex
                                  maxWidth="1440px"
                                  m="auto"
                                  alignItems="center"
                                  justifyContent="center"
                                  width="100%"
                                  height="100vh"
                                  css={{
                                      "@media (max-width: 1200px)": {
                                          height: "100%",
                                      },
                                  }}
                              >
                                <Chakra.Image
                                    src={image?.localFile?.publicURL}
                                    width="100%"
                                    height="min(880px, 70vh)"
                                    maxWidth="min(1000px, 84vh)"
                                    m="auto"
                                    objectFit="contain"
                                    css={{
                                      "@media (max-width: 1200px)": {
                                        maxWidth: "100%",
                                          height: "unset"
                                      },
                                    }}
                                />
                              </Chakra.Flex>
                            </Chakra.Box>
                        )
                      } else if (
                          fieldGroupName === "Post_Postgallery_Gallery_Video"
                      ) {
                        return (<VideoSection url={video} index={index} />)
                      } else {
                        return (<VideoSection url={video} index={index} />)
                      }
                    })}
                  </Chakra.Box>
                </Chakra.Box>
                {gallery && gallery.length > 1 &&  <Chakra.Button
                    zIndex={10}
                    color="black"
                    display="inline-block"
                    padding="0.5rem 1rem"
                    borderColor="transparent"
                    borderWidth="1px"
                    alignSelf="center"
                    borderRadius="4px"
                    onClick={() => slider?.current?.prev()}
                    backgroundColor="transparent"
                    css={{
                        "@media (max-width: 1200px)": {
                            position: "absolute",
                            bottom: "-40px",
                            left: "20px"
                        },
                    }}
                >
                    <LeftArrow />
                </Chakra.Button>}

                {gallery && gallery.length > 1 && <Chakra.Button
                    zIndex={10}
                    display="inline-block"
                    padding="0.5rem 1rem"
                    borderColor="transparent"
                    borderWidth="1px"
                    alignSelf="center"
                    borderRadius="4px"
                    onClick={() => slider?.current?.next()}
                    backgroundColor="transparent"
                    css={{
                        "@media (max-width: 1200px)": {
                            position: "absolute",
                            bottom: "-40px",
                            right: "20px "
                        },
                    }}
                >
                    <RightArrow />
                </Chakra.Button>}
                <Chakra.Box
                    width="fit-content"
                    height="auto"
                    display="flex"
                    alignItems="flex-end"
                    marginBottom="79px"
                    justifyContent="space-between"
                    flexDirection="column"
                    position="fixed"
                    bottom="0"
                >
                    <Chakra.Box
                        width="100%"
                        display="flex"
                        alignItems="flex-start"
                    >
                        <Chakra.Box
                            color="black"
                            pr="14px"
                            css={{ fill: "#000", color: "#000" }}
                        >
                            {type === "video" || type === "gif" ? (
                                <Play />
                            ) : (
                                <Photography />
                            )}
                        </Chakra.Box>
                        <Chakra.Box
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                            css={{
                                "@media (max-width: 479px)": {
                                    maxWidth: "66%"
                                }
                            }}
                        >
                            <Chakra.Box
                                as="span"
                                color="#000"
                                fontSize="11px"
                                fontFamily="TradeGothic LT Extended"
                                lineHeight="16px"
                                letterSpacing="1.8px"
                                textTransform="uppercase"
                                textAlign="left"
                            >
                                {title}
                            </Chakra.Box>
                            <Chakra.Box
                                as="span"
                                color="#000"
                                fontSize="11px"
                                fontFamily="TradeGothic LT Extended"
                                lineHeight="16px"
                                letterSpacing="1.8px"
                                textTransform="uppercase"
                                css={{
                                    "@media (max-width: 991px)": {
                                        fontSize: "12px",
                                        textTransform: "capitalize",
                                    },
                                }}
                            >
                                {category}
                            </Chakra.Box>
                        </Chakra.Box>
                    </Chakra.Box>
                    {/*slider && <Chakra.Box
                as="span"
                color="#000"
                fontSize="11px"
                fontFamily="TradeGothic LT Extended"
                lineHeight="12px"
                whiteSpace="nowrap"
                letterSpacing="2px"
              >
                {currentSlide + 1} / {slider.details().size}
              </Chakra.Box>*/}
                </Chakra.Box>
            </Chakra.Flex>

        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Flex>
  )
}

export default WpPost

export const query = graphql`
  query ($id: String) {
    site {
      buildTime
    }
    wpPost(id: { eq: $id }) {
      template {
        templateName
      }
      date(formatString: "LL")
      title
      categories {
        nodes {
          name
        }
      }
      postGallery {
        gallery {
          ... on WpPost_Postgallery_Gallery_Image {
            fieldGroupName
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
          ... on WpPost_Postgallery_Gallery_Video {
            video
            fieldGroupName
          }
          ... on WpPost_Postgallery_Gallery_Gif {
            gif
            fieldGroupName
          }
        }
        primaryContentType
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
